export enum Colors {
  white = '#FFFFFF',

  // Primary
  grape = '#291F7D',
  primary = '#5440F7',
  linkHover = '#4C3AE0',
  brandPastel = '#6857e4',
  lavender = '#BBB3FF',
  titanPurple = '#E8E6FF',
  melrose = '#F4F2FF',
  pastel = '#6857e4',
  navy = '#111135',

  // Greys
  storm = '#6F738C',
  iron = '#CECED9',
  athens = '#EDEDF2',
  background = '#F5F5F7',
  ghost = '#E1E4E8',

  // Blues
  night = '#171928',
  shark = '#262940',
  midnight = '#0C257A',
  blue = '#205CFA',
  bluePastel = '#3073F5',
  blueRiver = '#22A7F1',
  cloud = '#98B6ED',

  frog = '#175954',
  // Greens
  green = '#0BB07B',
  grass = '#2B9890',
  fresco = '#B5D3D3',
  fountain = '#47C4BB',
  aqua = '#5AE2DE',
  aquaPastel = '#86E3E3',
  river = '#DBFFFD',
  twilight = '#F7FFFF',
  sky = '#F0FFFE',

  // Reds
  red = '#DD2727',
  maroon = '#800D0D',
  strawberry = '#FFEFF0',

  // Oranges
  orange = '#FFAD0D',
  peach = '#FACD75',
  carrot = '#FFF4DD',
  dawn = '#FFFBF3',
}
